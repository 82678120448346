export const DETAILS_FORM_SET_PRISTINE = 'DETAILS_FORM_SET_PRISTINE';

export const DETAILS_FORM_SET_LOADING = 'DETAILS_FORM_SET_LOADING';

export const SET_OPT_OUT_MARKETING = 'SET_OPT_OUT_MARKETING';

export const CHECK_PAPER_BILLING = 'CHECK_PAPER_BILLING';
export const DETAILS_FORM_SUBMIT = 'DETAILS_FORM_SUBMIT';
export const DETAILS_FORM_UPDATE = 'DETAILS_FORM_UPDATE';
export const TERMS_AND_CONDITIONS_UPDATE = 'TERMS_AND_CONDITIONS_UPDATE';
export const UPDATE_BOTH_FORMS = 'UPDATE_BOTH_FORMS';
export const UPDATE_LANDLINE_NUMBER = 'UPDATE_LANDLINE_NUMBER';

export const TOUCH_DETAILS_FORM = 'TOUCH_DETAILS_FORM';

export const FORM_SECTION_SUBMISSION_FAILED = 'FORM_SECTION_SUBMISSION_FAILED';
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';
export const DELETE_FORM_ERROR = 'DELETE_FORM_ERROR';
export const SET_SCROLL_TO_ERROR = 'SET_SCROLL_TO_ERROR';

export const DETAILS_FORM_FORM_SET_VALIDATION = 'DETAILS_FORM_SET_VALIDATION';
export const DETAILS_FORM_VALIDATE = 'DETAILS_FORM_VALIDATE';
export const SET_PAPER_BILLING_MODAL = 'SET_PAPER_BILLING_MODAL';
export const UPDATE_DIRECT_DEBIT_NAME = 'UPDATE_DIRECT_DEBIT_NAME';
export const VALIDATE_BILLING_ADDRESS = 'VALIDATE_BILLING_ADDRESS';

export const GET_FORM_INITIAL_DATA = 'GET_FORM_INITIAL_DATA';
export const SET_CREDIT_CHECK = 'SET_CREDIT_CHECK';

export const DETAILS_REQUEST_EECC_CONTRACT = 'DETAILS_REQUEST_EECC_CONTRACT';
export const DETAILS_REQUEST_EECC_CONTRACT_SUCCESS =
  'DETAILS_REQUEST_EECC_CONTRACT_SUCCESS';
export const DETAILS_REQUEST_EECC_CONTRACT_FAILURE =
  'DETAILS_REQUEST_EECC_CONTRACT_FAILURE';

export const CONTRACT_GENERATION_LOADING = 'CONTRACT_GENERATION_LOADING';
export const CONTRACT_GENERATION_SUCCESS = 'CONTRACT_GENERATION_SUCCESS';
export const CONTRACT_GENERATION_FAILURE = 'CONTRACT_GENERATION_FAILURE';
export const EECC_CONTRACT_LINK_CLICKED = 'EECC_CONTRACT_LINK_CLICKED';

export const CLEAR_OTS_FORM = 'CLEAR_OTS_FORM';
